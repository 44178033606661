import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Terms = () => (
  <Layout>
    <Helmet
    bodyAttributes={{
      class: 'legal-page',
    }}
    />
    <section id="legal" className="container container--content">
      <Seo title="Terms of use" />
      <h1>Terms of use</h1>
      <p>
        Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec sed
        odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
        Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis
        mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia
        odio sem nec elit. Donec id elit non mi porta gravida at eget metus.
        Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
        sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
      </p>
      <h3>Subheading</h3>
      <p>
        Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
        ut fermentum massa justo sit amet risus. Maecenas sed diam eget risus
        varius blandit sit amet non magna. Nullam id dolor id nibh ultricies
        vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor
        mauris condimentum nibh, ut fermentum massa justo sit amet risus. Fusce
        dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
        fermentum massa justo sit amet risus. Duis mollis, est non commodo
        luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
        Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus
        eget urna mollis ornare vel eu leo. Donec sed odio dui. Fusce dapibus,
        tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
        massa justo sit amet risus. Cum sociis natoque penatibus et magnis dis
        parturient montes, nascetur ridiculus mus. Maecenas sed diam eget risus
        varius blandit sit amet non magna. Aenean lacinia bibendum nulla sed
        consectetur.
      </p>
      <h3>Subheading</h3>
      <p>
        Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
        ut fermentum massa justo sit amet risus. Maecenas sed diam eget risus
        varius blandit sit amet non magna. Nullam id dolor id nibh ultricies
        vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor
        mauris condimentum nibh, ut fermentum massa justo sit amet risus. Fusce
        dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
        fermentum massa justo sit amet risus. Duis mollis, est non commodo
        luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.{' '}
      </p>
      <ul>
        <li>
          Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
          nibh, ut fermentum massa justo sit amet risus.{' '}
        </li>
        <li>
          Maecenas sed diam eget risus varius blandit sit amet non magna.{' '}
        </li>
        <li>Nullam id dolor id nibh ultricies vehicula ut id elit. </li>
        <li>
          Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
          nibh, ut fermentum massa justo sit amet risus.
        </li>
      </ul>
      <p>
        Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
        ut fermentum massa justo sit amet risus. Maecenas sed diam eget risus
        varius blandit sit amet non magna. Nullam id dolor id nibh ultricies
        vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor
        mauris condimentum nibh, ut fermentum massa justo sit amet risus. Fusce
        dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
        fermentum massa justo sit amet risus. Duis mollis, est non commodo
        luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.{' '}
      </p>
    </section>
  </Layout>
)

export default Terms
